<template>
  <div class="apply-member">
    <!-- <img v-if="status == 0" src="@/assets/img/applyMembers/four.png" class="four" /> -->
    <div class="apply-holder">
      <div :class="['a-h flex', {'a-three': status == 2 }]">
        <img src="@/assets/img/store/picture.png" class="pic">
        <div class="h-center">
          <p>灵龙种草达人v{{userInfo.memberType}}</p>
          <!-- <p>邀请码：{{userInfo.invitationCode}}</p> -->
        </div>
        <div v-if="isAuth" class="h-right flex align-center"  @click="goShopAuthFunc">
          <p>升级成为门店达人</p>
          <img src="@/assets/img/applyMembers/right.png" class="right">
        </div>
        <div v-if="status == 0" class="flex align-center h-second">
          <img src="@/assets/img/store/clock.png" class="clock">
          <p>门店认证审核中</p>
        </div>
        <div v-if="status == 2" class="flex align-center h-third">
          <img src="@/assets/img/store/close.png" class="clock">
          <p class="fail">门店认证失败</p>
          <div class="b-b">
            <p class="reason">失败原因：{{refuseReason}}</p>
            <p class="edit" @click="goShopAuthFunc">修改认证信息再提交</p>
          </div>
        </div>
      </div>
      <div :class="['a-ul', {'a-ul-three': status == 3 }]" @click="goHomeFunc">
        <div class="a-li flex flex-between">
          <img src="@/assets/img/applyMembers/pack.png" class="left">
          <div class="line flex align-center">
            <p>给身边人种草优惠好物</p>
            <img src="@/assets/img/applyMembers/right-second.png" class="right">
          </div>
        </div>
        <div class="a-li flex flex-between">
          <img src="@/assets/img/applyMembers/mon.png" class="left">
          <div class="line flex align-center">
            <p>推荐灵龙会员成为种草达人</p>
            <img src="@/assets/img/applyMembers/right-second.png" class="right">
          </div>
        </div>
        <div class="a-li flex flex-between li-third">
          <img src="@/assets/img/applyMembers/mon.png" class="left">
          <div class="line flex align-center">
            <p>优惠自购所需吃的、喝的、用的、<br>得更多实用龙珠</p>
            <img src="@/assets/img/applyMembers/right-second.png" class="right">
          </div>
        </div>
      </div>
      <div class="box-two flex flex-between">
        <p @click="goPageFunc(0)">查看我的粉丝</p>
        <p @click="goPageFunc(1)">查看我的收益</p>
      </div>
    </div>
    <div :class="['tilte flex align-center flex-center', {'status-two': status == 1 || status == 2 }]">
      <img src="@/assets/img/applyMembers/14.png" class="left" />
      <p>达人权益介绍</p>
      <img src="@/assets/img/applyMembers/15.png" class="right" />
    </div>
    <div class="a-box">
      <p class="a-title">专享商品优惠价</p>
      <div class="hold" style="display:none;">
        <div class="t-h flex">
          <p>168件品牌商品</p>
          <img src="@/assets/img/applyMembers/11.png" alt="" />
        </div>
        <div class="s-h flex">
          <div class="s-li" v-for="item in goodsList" :key="item.id" @click="gotoPagesFunc(item.goodId)">
            <div class="s-li-img" :style="{background:'url('+item.goodPic+')no-repeat center center',backgroundSize: 'cover'}"></div>
            <p class="t-t">{{item.goodName}}</p>
            <!-- <p class="t-d">30ml</p> -->
            <p class="b-b flex align-end">
              低至<span class="l-s"><i>¥</i>{{item.price}}</span
              ><span class="r-s">/件</span>
            </p>
          </div>
        </div>
      </div>
      <div class="hold1">
        <img src="http://sfwx.cn-sh2.ufileos.com/banner/banner1.png" alt="">
      </div>
    </div>
    <div class="a-box second-box">
      <p class="a-title">推广灵龙惠生活商品 得佣金</p>
      <div class="hold" style="display:none;">
        <div class="t-h flex">
          <p style="color: #ac6a1a">全平台商品均有</p>
          <img src="@/assets/img/applyMembers/10.png" style="margin-top: 3px" />
        </div>
        <div class="s-h flex">
          <div class="s-li" v-for="item in goodsList1" :key="item.id" @click="gotoPagesFunc(item.goodId)">
            <div class="s-li-img" :style="{background:'url('+item.goodPic+')no-repeat center center',backgroundSize: 'cover'}"></div>
            <p class="t-t">{{item.goodName}}</p>
            <!-- <p class="t-d">30ml</p> -->
            <p class="b-b flex align-end">
              佣金<span class="l-s"><i>¥</i>{{parseFloat(item.returnAmount/100).toFixed(2)}}</span>
            </p>
          </div>
        </div>
      </div>
      <div class="hold1">
        <img src="http://sfwx.cn-sh2.ufileos.com/banner/banner2.png" alt="">
      </div>
    </div>
    <div class="a-box">
      <p class="a-title">推荐身边好友成为达人，得推荐奖励</p>
      <div class="s-hold flex">
        <div class="s-h-l">
          <div class="one flex align-center">
            <p>推荐</p>
            <img src="@/assets/img/applyMembers/one.png" alt="" />
            <p>好友成为灵龙种草达人</p>
          </div>
          <img src="@/assets/img/applyMembers/9.png" class="center">
          <p class="bottom">追求极简生活、追求性价比的<br>生活理念 传播给身边人</p>
        </div>
        <div class="s-h-r">
          <img src="@/assets/img/applyMembers/three.png" class="s-h-r-img" />
          <div class="s-h-r-price">50<span>元</span></div>
        </div>
      </div>
    </div>
    <div class="a-box four-box" style="display:none;">
      <p class="a-title">定期可免费参加平台基础新媒体培训课程</p>
      <div class="t-hold">
          <div class="t-top flex flex-center">
              <img src="@/assets/img/applyMembers/eight.png" alt="">
              <p>学习了解各种其他渠道做传播、带货的基础知识</p>
              <img src="@/assets/img/applyMembers/eight.png" alt="">
          </div>
          <div class="t-ul flex flex-between">
              <div class="t-li">
                  <img src="@/assets/img/applyMembers/six.png" alt="">
                  <p>抖音</p>
              </div>
              <div class="t-li">
                  <img src="@/assets/img/applyMembers/five.png" alt="">
                  <p>快手</p>
              </div>
              <div class="t-li">
                  <img src="@/assets/img/applyMembers/seven.png" alt="">
                  <p>小红书</p>
              </div>
              <div class="t-li">
                  <img src="@/assets/img/applyMembers/two.png" alt="">
                  <p>微信视频号</p>
              </div>
          </div>
          <p class="s-b-b">价值<i>9999元</i>的课程～达人<span>免费尊享</span></p>
      </div>
    </div>
  </div>
</template>
<script>
import {
    getShopAuth,
    getweixininfo
} from '@/utils/user.js';
import {
    getHomeGoods,
} from '@/utils/home.js';
export default {
    name: "ApplyMembers",
    data() {
        return {
            isAuth: true,
            status: -1,
            goodsList: [],
            goodsList1: [],
            userInfo: '',
            refuseReason: '',
        }
    },
    created() {
      this.initData();
      this.shopStatus();
      // 种草商品
      this.getHomeGoodsFunc(3);
      // 普通商品
      this.getHomeGoodsFunc(0);
    },
    methods: {
      // 获取门店达人审核状态
      shopStatus() {
          let data = {
              // userId: this.$cookies.get('userId')
              memberId: this.$cookies.get('memberId')
          };
          getShopAuth(data).then(res => {
            console.log('resShop', res)
              if (res.code == 0) {
                this.isAuth = res.data.status ? true : false;
                this.status = res.data.status;
                this.refuseReason = res.data.refuseReason
              }
          })
      },
      // 获取用户邀请码
      initData() {
            let data = {
                memberId: this.$cookies.get('memberId')
            };
            getweixininfo(data).then(res => {
                if (res && res.code == "0") {
                    this.userInfo = res.data || {}
                }
            })
        },
      // 升级成为门店达人
      goShopAuthFunc() {
        this.$router.push({
          path: '/storeAuth'
        })
      },
      // 查看我的粉丝 我的收益
      goPageFunc(idx) {
        let url = '';
        switch(idx) {
          case 0:
            url= '/myFans';
            break;
          case 1:
            url= '/profitRecord';
            break;
        }
        this.$router.push({
          path: url
        })
      },
      // 获取商品列表
      getHomeGoodsFunc(channel) {
          let data = {
              memberId: this.$cookies.get('memberId'),
              goodName: '',
              sortMethod: 1,
              pageNo: 1,
              pageSize: 4,
              channel: channel, //(0=普通专区,1=限时积分专区,2=达人专区,3非达人种草商品)
              categoryFirstId: '',
              tag: ''
          }
          getHomeGoods(data).then(res => {
              console.log('getHomeGoods', res)
              if (res.result) {
                  let arr = res.result.goodList;
                  if (channel == 3) {
                    this.goodsList = arr;
                  } else {
                    this.goodsList1 = arr;
                  }
              }
          })
      },
      // 跳转商品详情
      gotoPagesFunc(id) {
        this.$router.push({
            path: '/goodsDetail',
            query: {
              goodsId: id
            }
        })
      },
      // 跳转到首页
      goHomeFunc() {
        this.$router.push({
          path: '/'
        })
      }
    }
}
</script>
<style lang="scss" scoped>
.apply-member{
    width: 100%;
    height: auto;
    background: #3D3D3D;
    padding-bottom: 20px;
    .four{
        width: 100%;
    }
    .apply-holder{
        padding-top: 15px;
        .a-h{
            width: 345px;
            margin: 0 auto;
            .pic{
                width: 52px;
                height: 52px;
                flex-shrink: 0;
            }
            .h-center{
              padding-top: 5px;
              margin-left: 10px;
              flex-grow: 1;
              p:nth-child(1) {
                height: 21px;
                font-size: 15px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #FFFFFF;
                line-height: 21px;
                margin-bottom: 5px;
              }
              p:nth-child(2) {
                height: 16px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(255, 255, 255, 0.8);
                line-height: 16px;
              }
            }
            .h-right{
                width: auto;
                height: 25px;
                background: linear-gradient(270deg, #F4D191 0%, #FDEABE 58%, #F9E9BE 100%);
                border-radius: 12px;
                margin-top: 12px;
                p{
                    font-size: 12px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #AB6E14;
                    margin-left: 10px;
                }
                img.right{
                    width: 16px;
                    height: 16px;
                    flex-shrink: 0;
                }
            }
            .h-second{
                width: 121px;
                height: 26px;
                background: #FCDDB1;
                border-radius: 14px;
                margin-top: 13px;
                .clock{
                    width: 18px;
                    height: 18px;
                    flex-shrink: 0;
                    margin:0 4px 0 9px;
                }
                p{
                    font-size: 11px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #CD8712;
                }
            }
            .h-third{
                width: 110px;
                height: 26px;
                background: #ECECEC;
                border-radius: 14px;;
                margin-top: 5px;
                position: relative;
                .clock{
                    width: 18px;
                    height: 18px;
                    flex-shrink: 0;
                    margin:0 4px 0 9px;
                }
                p.fail{
                    font-size: 11px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #999999;
                }
                .b-b{
                    width: 170px;
                    height: 69px;
                    background: rgba(255, 255, 255, 0.2);
                    border-radius: 10px;
                    padding-top: 10px;
                    position: absolute;
                    right: 0;
                    top: 31px;
                    .reason{
                        font-size: 12px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: rgba(255, 255, 255, 0.7);
                        text-align: center;
                    }
                    .edit{
                        width: 140px;
                        height: 32px;
                        line-height: 32px;
                        text-align: center;
                        border-radius: 16px;
                        border: 1px solid rgba(255, 255, 255, 0.49);
                        margin: 10px auto 0;
                        font-size: 12px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: rgba(255, 255, 255, 0.7);
                    }
                }
            }
        }
        .a-three{
            height: 115px;
        }
        .a-ul{
            width: 316px;
            height: 190px;
            background: rgba(255, 255, 255, 0.15);
            border-radius: 6px;
            margin: 20px auto 25px;
            padding: 14px 15px 0 14px;
            .a-li{
                height: 46px;
                margin-bottom: 9px;
                padding-bottom: 9px;
                img.left{
                    width: 46px;
                    height: 46px;
                    flex-shrink: 0;
                }
                .line{
                    width: 237px;
                    height: 46px;
                    border-bottom: solid 1px #3D3D3D;
                    padding-bottom: 8px;
                }
                p{
                    flex-grow: 1;
                    font-size: 13px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FCEABE;
                    line-height: 18px;
                    text-align: right;
                }
                img.right{
                    width: 16px;
                    height: 16px;
                    flex-shrink: 0;
                    margin-left: 6px;
                }
            }
            .li-third{
                margin-bottom: 0;
                padding-bottom: 0;
                .line{
                    border: none;
                }
            }
        }
        .a-ul-three{
            margin-top: 15px;
        }
        .box-two{
            width: 345px; 
            margin: 0 auto;
            p{
                width: 165px;
                height: 44px;
                border-radius: 22px;
                opacity: 0.7;
                border: 1px solid rgba(255, 255, 255, 0.7);
                line-height: 44px;
                text-align: center;
                font-size: 15px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: rgba(255, 255, 255, 0.7);
            }
        }
    }
    .tilte{
        height: 22px;
        margin-top: 20px;
        img{
            width: 57px;
            height: 8px;
        }
        p{
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFDCA2;
            margin: 0 23px 0 24px;
        }
    }
    .status-two{
        margin-top: 30px !important;
    }
    .a-box{
        width: 345px;
        margin: 21px auto 0;
        .t-hold{
            height: 165px;
            background: rgba(255, 255, 255, 0.95);
            border-radius: 10px;
            padding: 15px 0 0;
            position: relative;
            overflow: hidden;
            .t-top{
                height: 16px;
                img{
                    flex-shrink: 0;
                    width: 16px;
                    height: 14px;
                }
                p{
                    margin: 0 8px 0 10px;
                    height: 16px;
                    font-size: 11px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #AC6A1A;
                    line-height: 16px;
                }
            }
            .t-ul{
                padding: 24px 34px 0 33px;
                .t-li{
                    width: 48px;
                    img{
                        width: 48px;
                        height: 48px;
                    }
                    p{
                        display: flex;
                        justify-content: center;
                        width: 48px;
                        white-space:nowrap;
                        height: 14px;
                        font-size: 10px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #666666;
                        line-height: 14px;
                        margin-top: 5px;
                    }
                }
            }
            .s-b-b{
                width: 100%;
                height: 44px;
                background: linear-gradient(135deg, #545454 0%, #A1A1A1 68%, #585858 100%);
                -webkit-border-radius: 0px 0px 10px 10px;
                border-radius: 0px 0px 10px 10px;
                text-align: center;
                line-height: 44px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                position: absolute;
                bottom: 0;
                left: 0;
                i{
                    font-style: normal;
                    font-weight: 600;
                }
                span{
                  color: #FFE3C5;
                  font-weight: bold;
                }
            }
        }
        .s-hold{
            height: 123px;
            padding: 17px 20px 0 0;
            background: #ffffff;
            -webkit-border-radius: 10px;
            border-radius: 10px;
            opacity: 0.95;
            margin-top: 9px;
            .s-h-l{
              flex-grow: 1;
              .one{
                height: 16px;
                font-size: 11px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #3D3D3D;
                margin-left: 15px;
                text-align: left;
                img{
                  width: 16px;
                  height: 10px;
                  margin: 0 6px;
                }
              }
              .center{
                  width: 183px;
                  height: 17px;
                  margin: 9px 0 18px 16px;
              }
              .bottom{
                font-size: 10px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
                line-height: 14px;
                margin-left: 14px;
              }
            }
            .s-h-r{
                position: relative;
                flex-shrink: 0;
                width: 80px;
                height: 106px;
                &-img {
                    width: 80px;
                    height: 106px;
                    vertical-align: top;
                }
                &-price {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 45px;
                    color: #FB1B24;
                    font-weight: bold;
                    text-align: center;
                    font-size: 30px;
                    font-family: Antonio-Bold, Antonio;
                    span {
                        font-size: 10px;
                        font-weight: 600;
                    }
                }
            }
        }
        .a-title{
            height: 24px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #B9A991;
            line-height: 24px;
            text-align: left;
            position: relative;
            text-indent: 14px;
        }
        .a-title::before{
            content: "";
            width: 3px;
            height: 14px;
            background: #B4996F;
            -webkit-border-radius: 4px;
            border-radius: 4px;
            position: absolute;
            left: 0;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
        }
        .hold{
            height: 170px;
            background: url("../assets/img/applyMembers/13.png") no-repeat;
            background-size: 100% 100%;
            margin-top: 9px;
            padding: 10px 6px 0 15px;
            .t-h{
                height: 21px;
               p{
                height: 21px;
                font-size: 15px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #E62129;
                line-height: 21px;
               }
               img{
                 width: 58px;
                 height: 15px;
                 margin: 4px 0 0 6px;
               }
            }
            .s-h{
                margin-top: 12px;
                text-align: left;
                .s-li{
                    width: 81px;
                    &-img{
                        width: 72px;
                        height: 72px;
                        border-radius: 5px;
                        overflow: hidden;
                        margin-bottom: 8px;
                    }
                    .t-t,.t-d{
                        white-space:nowrap;
                        overflow:hidden;
                        text-overflow:ellipsis;
                        height: 14px;
                        font-size: 10px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        line-height: 14px;
                    }
                    .t-t{
                        color: #333333;
                    }
                    .t-d{
                        color: #999999;
                    }
                    .b-b{
                        height: 14px;
                        font-size: 10px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                        line-height: 14px;
                        white-space:nowrap;
                        margin-top: 8px;
                        span{
                            color: #E62129;
                            font-size: 12px;
                            i{
                                font-style: normal;
                            }
                        }
                        .l-s{
                            font-family: Antonio-Bold, Antonio;
                            font-weight: bold;
                        }
                        .r-s{
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                        }
                    }
                }
            }
        }
        .hold1 {
            width: 100%;
            height: auto;
            margin-top: 10px;
            img {
                width: 100%;
                height: auto;
                vertical-align: top;
            }
        }
    }
    .second-box{
        .hold{
            background: url("../assets/img/applyMembers/12.png") no-repeat;
            background-size: 100% 100%;
        }
    }
    .four-box{
        padding-bottom: 13px;
    }
}
</style>