<template>
  <div class="some-audit">
    <!-- <div class="header" v-if="!isType">
      <p class="top flex align-center flex-center">
        您已购买种草商品~<br />订单收货完成后即可自动升级达人身份！<br />先请选择灵龙小主的达人身份
      </p>
      <div class="sel-box flex flex-center">
        <div v-for="(item,index) in selectList" :key="index" class="part-li flex align-center" @click="selectTypeFunc(index)">
            <img v-show="!item.select" src="@/assets/img/store/no-select.png" alt="">
            <img v-show="item.select" src="@/assets/img/store/select-two.png" alt="">
            <p :class="{'one-title': index == 0 }">{{ item.title }}</p>
            </div>
      </div>
      <p class="submit" @click="submitFun">提交审核</p>
    </div> -->
    <div class="header-other">
      <div :class="['other-top', { 'third-aduit': isAudit == 2 }]">
        <div class="top-holder flex">
          <img src="@/assets/img/store/picture.png" class="pic" />
          <div class="t-center">
            <p class="first-p">灵龙小主人身份</p>
            <p class="second-p">个人达人</p>
            <div
              v-if="isAudit == 1 || isAudit == 2"
              :class="[
                'audit flex align-center',
                { 'audit-fail': isAudit == 2 },
              ]"
            >
              <img
                v-if="isAudit == 1"
                src="@/assets/img/store/clock.png"
                alt=""
              />
              <img
                v-if="isAudit == 2"
                src="@/assets/img/store/close.png"
                alt=""
              />
              <p class="third-p">
                {{ isAudit == 1 ? "平台正在审核身份中" : "平台审核身份失败" }}
              </p>
            </div>
            <div v-if="isAudit == 2" class="des-fail">
              <p class="reason">
                失败原因：<br />照片模糊，无法看清，请重新上传营业执照
              </p>
              <p class="again">修改门店达人认证信息，重新提交</p>
            </div>
          </div>
          <!-- <p class="edit">修改身份</p> -->
        </div>
      </div>
      <div
        :class="[
          'other-bottom',
          { 'is-audit': isAudit == 1 },
          { 'third-bottom': isAudit == 2 },
        ]"
      >
        <p class="desc">
          您已购买种草商品~<br />订单收货完成后即可自动升级达人身份！
        </p>
        <p class="look" @click="goOrderListFunc">查看种草订单进度</p>
      </div>
    </div>
    <div class="main">
      <p class="title">灵龙惠生活达人不同身份权益说明</p>
      <p class="sub-title">个人达人权益：</p>
      <p class="sub-content">
        1、无需囤货、无需发货、一键分享，有下单就能赚钱，平台提供完整的购买服务体系，——件代发，卖1单赚1笔，卖不掉0损失。<br />
        2、自购返龙珠，比普通会员高出最多20%的积分奖励，享受更多优惠的价格。<br />
        3、精美素材便捷发布：所有商品素材，专业的小编提前准备，直接转发商品，轻松推广。<br />
        4、实时激励随时提现：收益实时展示，佣金提现方便。<br />
        5、在微信中直接交易，支持微信支付，无需下载任何APP。<br />
        6、成功推荐身边灵龙小主成为达人，可获得一次性50元奖励。具体成功推荐条件请看《如何成功灵龙惠生活达人》。
      </p>
      <p class="sub-title two-sub">门店达人权益：</p>
      <p class="sub-content">
        1、拥有门店特卖采购专区：<br />平台直供直采、保证低价、可开发票。
        2、自购返积分，比普通会员高出最多20%的龙珠奖励，采购时享受更多优惠的价格。<br />
        3、帮您扩建云货架、搭建更多生意收入:在云货架上无需囤货、无需发货、一键分享，有粉丝下单就能赚钱，平台提供完整的购买服务体系，——件代发，卖1单赚1笔，卖不掉0损失。<br />
        4、实时激励随时提现：收益实时展示，佣金提现方便。<br />
        5、精美素材便捷发布：所有商品素材，专业的小编提前准备，直接转发商品，轻松推广。<br />
        6、在微信中直接交易，支持微信支付，无需下载任何APP。<br />
        7、成功推荐身边灵龙小主成为达人，可获得一次性50元奖励。具体成功推荐条件请看《如何成功灵龙惠生活达人》。
      </p>
    </div>
    <van-popup
      v-model:show="showSuccess"
      position="center"
      :style="{ width: '100%' }"
      :close-on-click-overlay="false"
      class="audit-some-popup"
    >
      <div class="box-reset">
        <img src="@/assets/img/loginRegister/success.png" alt="" />
        <p class="box-reset-text">已成功提交</p>
        <p class="box-reset-tip">
          等待小主您将订单完成后<br />可自动升级至达人
        </p>
        <p class="box-reset-login" @click="knowFun">我已知晓</p>
      </div>
    </van-popup>
  </div>
</template>
<script>
export default {
  name: "SomeAudit",
  data() {
    return {
      isAudit: 0,
      showSuccess: false,
      selectList: [
        {
          select: false,
          title: "个人达人",
        },
        {
          select: false,
          title: "门店达人",
        },
      ],
      isType: false,
    };
  },
  methods: {
    submitFun() {
      // let arr = this.selectList;
      // let aLength = 0;
      // arr.forEach(item =>  {
      //   if(item.select == true) {
      //     aLength++;
      //   }
      // })

      // if (aLength > 0) {
      this.showSuccess = true;
      // } else {
      //   this.$toast({
      //     message: '请选择身份'
      //   })
      // }
    },
    knowFun() {
      this.showSuccess = false;
      // this.isType = true;
      // this.isAudit = 0;
    },
    // 选择身份
    selectTypeFunc(idx) {
      let arr = this.selectList;
      arr.forEach((item) => {
        item.select = false;
      });
      arr[idx].select = true;
      this.selectList = arr;
    },
    // 跳转到订单列表
    goOrderListFunc() {
      this.$router.push({
        path: "/orderList",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.some-audit {
  width: 100%;
  height: auto;
  .header {
    width: 345px;
    background: #ffffff;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    margin: 0 auto;
    margin-top: 15px;
    padding-bottom: 15px;
    p.top {
      background: #fff2f2;
      height: auto;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #e62129;
      text-align: center;
      padding: 15px 0px;
      line-height: 1.5;
      -webkit-border-radius: 10px;
      border-radius: 10px;
    }
    .sel-box {
      margin: 15px 0 20px;
      .part-li {
        height: 20px;
        img {
          width: 16px;
          height: 16px;
          margin-right: 5px;
        }
        p {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          text-align: left;
        }
        .one-title {
          width: 137px;
        }
      }
    }
    .submit {
      width: 110px;
      height: 32px;
      border-radius: 16px;
      border: 1px solid #e62129;
      line-height: 32px;
      text-align: center;
      margin: 0 auto;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #e62129;
    }
  }
  .header-other {
    .third-aduit {
      height: 246px !important;
    }
    .other-top {
      position: relative;
      height: 127px;
      background: #e62129;
      padding-top: 19px;
      .top-holder {
        width: 345px;
        margin: 0 auto;
      }
      img.pic {
        width: 50px;
        height: 50px;
        flex-shrink: 0;
      }
      .t-center {
        flex-grow: 1;
        margin: 5px 0 0 9px;
        .first-p {
          height: 16px;
          font-size: 11px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 16px;
        }
        .second-p {
          height: 22px;
          font-size: 16px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          line-height: 22px;
          margin-top: 3px;
        }
        .audit {
          width: 144px;
          height: 26px;
          background: #fad4b3;
          border-radius: 14px;
          margin: 10px 0 0 -2px;
          img {
            width: 18px;
            height: 18px;
            margin: 0 4px 0 9px;
          }
          .third-p {
            font-size: 11px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #cd8712;
          }
        }
        .audit-fail {
          background: #ececec !important;
          p {
            color: #999999 !important;
          }
        }
        .des-fail {
          margin: 10px 0 0 -2px;
          width: 284px;
          height: 88px;
          background: rgba(255, 255, 255, 0.2);
          border-radius: 10px;
          padding: 10px 0 0;
          .reason {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(255, 255, 255, 0.7);
            line-height: 17px;
            margin: 0 0 10px 15px;
          }
          .again {
            width: 213px;
            height: 32px;
            border-radius: 16px;
            margin: 0 0 0 15px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(255, 255, 255, 0.7);
            line-height: 32px;
            text-align: center;
            border: 1px solid rgba(255, 255, 255, 0.49);
          }
        }
      }
      .edit {
        position: absolute;
        top: 27px;
        right: 0;
        width: 89px;
        height: 34px;
        background: rgba(255, 255, 255, 0.25);
        border-radius: 100px 0px 0px 100px;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 34px;
        text-indent: 20px;
      }
    }
    .is-audit {
      margin-top: -25px !important;
    }
    .third-bottom {
      margin-top: -36px !important;
    }
    .other-bottom {
      position: relative;
      width: 345px;
      height: 98px;
      background: #ffffff;
      -webkit-border-radius: 10px;
      border-radius: 10px;
      margin: -58px auto 0;
      padding-top: 15px;
      text-align: center;
      z-index: 1;
      .desc {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #e62129;
        line-height: 17px;
      }
      .look {
        width: 162px;
        height: 32px;
        line-height: 32px;
        border-radius: 16px;
        border: 1px solid #cccccc;
        text-align: center;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        margin: 15px auto 0;
      }
    }
  }
  .main {
    width: 315px;
    padding: 15px;
    margin: 15px auto;
    background: #ffffff;
    border-radius: 10px;
    .title {
      height: 21px;
      font-size: 15px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #0f0f0f;
      line-height: 21px;
      text-align: center;
      margin-bottom: 15px;
    }
    .sub-title {
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #666666;
      line-height: 20px;
      margin-bottom: 10px;
    }
    .sub-content {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #868991;
      line-height: 17px;
    }
    .two-sub {
      margin-top: 20px;
    }
  }
}
</style>
<style lang="scss">
.audit-some-popup {
  width: 280px !important;
  top: 188px !important;
  transform: translateX(-50%) !important;
  background: none !important;
  overflow: unset !important;
  .box-reset {
    position: relative;
    width: 280px;
    height: 152px;
    background: #ffffff;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;
    padding-top: 33px;
    img {
      width: 64px;
      height: 64px;
      position: absolute;
      left: 108px;
      top: -41px;
    }
    &-text {
      height: 21px;
      font-size: 15px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 21px;
      text-align: center;
    }
    &-tip {
      height: 44px;
      font-size: 15px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 21px;
      text-align: center;
      margin: 20px 0;
    }
    &-login {
      height: 46px;
      line-height: 46px;
      text-align: center;
      font-size: 15px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #e62129;
      border-top: solid 1px #f0f0f0;
    }
  }
}
</style>